@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.App-logo {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  max-height: 15vh;
}
.App-logo-pulse-v {
  animation: pulse 2s infinite;
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius:40% ;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
}

.App-header {
  background-color: transparent;
  text-align: center;
    display: flex;
    max-height: 35vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  position: relative;
  color: rgb(82, 76, 76);
  text-align: center;
}

.App-intro {
  font-size: large;
}
.content{
  max-height: 60vh;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
