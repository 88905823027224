body {
  background: rgb(228,147,195);
background: radial-gradient(circle, rgba(228,147,195,1) 0%, rgba(255,195,160,1) 100%);
  margin: 0;
  padding: 0;
  color: rgb(82, 76, 76);
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 3px;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  font-size: 20px;text-align: center;font-weight: bolder;border: black solid 2px;
  display: block;
  padding: 2px;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  justify-self: center;
  align-self: center;
  margin: auto;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.answerOption:hover {
  background-color: #eefbfe;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: #8bc53f url(/static/media/icon-check.4cffa739.svg) no-repeat;
  background-size: 27px;
  border-color: #8bc53f;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.App-logo {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  max-height: 15vh;
}
.App-logo-pulse-v {
  animation: pulse 2s infinite;
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius:40% ;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
}

.App-header {
  background-color: transparent;
  text-align: center;
    display: flex;
    max-height: 35vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  position: relative;
  color: rgb(82, 76, 76);
  text-align: center;
}

.App-intro {
  font-size: large;
}
.content{
  max-height: 60vh;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

